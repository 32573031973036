.Bgwhymetasoft{
    background-image: url("../../../assets/img/bg-why-metasoft.webp")   ;
    background-size: cover;
 
    
}
.btnwhychhose{

    display: inline-flex;
padding: 15.833px 39.583px;
justify-content: center;
align-items: center;
gap: 9.896px;
border-radius: 5.938px;
border: 1.979px solid #C52891;
color: #FFF;

font-size: 17.813px;

font-weight: 500;
line-height: normal;
text-transform: capitalize;
}
.btnwhychhose:hover {
    background-color: #C52891; /* Change the background color on hover */
    color: #FFF; /* Change the text color on hover if needed */
  }