.bannermodal {
    display: block;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/bgmenu.webp");
  }
  
  .bannermodal  .modal-content {
    position: absolute;
    top: 10%;
    left: 10%;
    transform: translate(-50%, -50%);
    /* background: #fff; */
    padding: 20px;
  }
  
  .bannermodal  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }


  .menu .modal-content {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    margin: 0;
    padding: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
   background-image: url("../../assets/img/bgmenu.webp");
   height: 100vh;
}
  @media (min-width: 768px) {
  .menu .modal-content {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    margin: 0;
    padding: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
   background-image: url("../../assets/img/bgmenu.webp");
   height: 100vh;
}
  }
  @media (min-width: 992px) {
    .menu .modal-content {
      /* display: flex;
      align-items: center;
      justify-content: center; */
      margin: 0;
      padding: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
     background-image: url("../../assets/img/bgmenu.webp");
     height: 100vh;
  }
    }
/* 
@keyframes gradient {
	0% {
		background-position: 50% 50%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 50% 50%;
	}
} */

  .menu .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }
  .modal-lg {
      max-width: 100%;
    }
  
    .bd-example-modal-lg .modal-dialog {
      max-width: 100%;
      margin: auto;
    }
    .btnmodal{
  
      display: inline-flex;
  padding: 15.833px 39.583px;
  justify-content: center;
  align-items: center;
  gap: 9.896px;
  border-radius: 5.938px;
  border: 1.979px solid #C52891;
  color: #FFF;
  font-family: Poppins;
  font-size: 28.813px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  }
  .btnmodal:hover {
    background-color: #C52891; /* Change the background color on hover */
    color: #FFF; /* Change the text color on hover if needed */
  }

  .menu .modal-header {
  border: none;
}
/* .rotating-image {
    animation: rotate 2s linear infinite; 
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
  .rotating-image {
    animation: rotate 1s linear forwards;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }

  .mainMenu  li{
    transition: all 0.3s ease;
  }