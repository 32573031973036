.Bg_Ourservice{
    background-image: url("../../assets/img/ourservice.webp");
    background-size: cover;
    height: 100vh;
}
.bg-service{
    background-image: url("../../assets/img/outservicebg.webp");
    background-size: cover;
}
.image_wrapper{
    margin: 0px 30px;
}
.image_wrapper img{
    width: 70%;
}

.btnfooter{

    display: inline-flex;
padding: 15.833px 39.583px;
justify-content: center;
align-items: center;
gap: 9.896px;
border-radius: 5.938px;
border: 1.979px solid #C52891;
color: #FFF;

font-size: 17.813px;

font-weight: 500;
line-height: normal;
text-transform: capitalize;
}
.btnfooter:hover {
  background-color: #C52891; /* Change the background color on hover */
  color: #FFF; /* Change the text color on hover if needed */
}
.Metasoftservice1{
    background-image: url("../../assets/img/metasoftservice1.webp");
    background-size: cover;
}
.imageservices {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
    img{
      transition: all 0.3s ease;
    }
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.image-overlay img {
    width: 100%; /* Adjust the width as needed */
    height: auto;
    transform: scale(1);
        transition: transform 0.5s ease-in-out;
}
.imageservices:hover img {
    transform: scale(1.2); /* Zoom-out effect on hover */
}

.bg-mastery{
    background-image:url("../../assets/img/masterybg.webp");
    background-size: cover;
    height: 100vh;
  
}
.bg-automation{
    background-image:url("../../assets/img/automationbg.webp");
    background-size: cover;
    height: 100vh;
  
}
.bg-iot{
    background-image:url("../../assets/img/iotbg.webp");
    background-size: cover;
    height: 100vh;
}
.bg-ai{
    background-image:url("../../assets/img/bg_ai.webp");
    background-size: cover;
    height: 100vh
}
/* /* .cloudservice_modal{

}

.cloudservice_modal .custom_content{
background-image: url("../../assets/img/cloudmodal.png");
margin: 0;
padding: 0;
height: 100vh;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
display: flex;
align-items: center;
justify-content: center;
max-width: 100%;
}
.cloudservice_modal .modal-full-width {
   
  } 

   */
   /* Add this style block in your CSS file or head of your HTML file */
.cloudservice_modal .modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 100vh;
  background-image: url("../../assets/img/cloudmodal.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.cloudservice_modal .modal-body {
  width: 100%; /* Ensure full width of modal body */
}
.modal-lg {
    max-width: 100%;
  }

  .bd-example-modal-lg .modal-dialog {
    max-width: 100%;
    margin: auto;
  }
  .btnmodal{

    display: inline-flex;
padding: 15.833px 39.583px;
justify-content: center;
align-items: center;
gap: 9.896px;
border-radius: 5.938px;
border: 1.979px solid #C52891;
color: #FFF;
font-family: Poppins;
font-size: 28.813px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
}
.wifisolution_modal .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/wifisolution_modal.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .wifisolution_modal .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }
  .antivirus .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/antivirus.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .antivirus .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }
  .backup .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/backup.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .backup .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }
  .email .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/email.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .email .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }

  .network .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/email.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .network .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }
  .storage .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/storage.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .storage .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }

  .server .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/Server.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .server .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }

  .signage .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/signage.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .signage .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }
  .interactive .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/interactive.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .interactive .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }
  .business .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/business.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .business .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }

  .pabx .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/pabx.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .pabx .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }

  .system .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/system.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .system .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }
  .cctv .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../../assets/img/cctv.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .cctv .modal-body {
    width: 100%; /* Ensure full width of modal body */
  }

  .btnothrservice{
    display: inline-flex;
padding: 15.833px 29.583px;
justify-content: center;
align-items: center;
gap: 9.896px;
border-radius: 5.938px;
border: 1.979px solid #C52891;
color: #FFF;

font-size: 24px;

font-weight: 500;
line-height: normal;
/* text-transform: capitalize; */
}

.btnothrservice:hover {
    background-color: #C52891; /* Change the background color on hover */
    color: #FFF; /* Change the text color on hover if needed */
  }