/* .Bgbanner{
    background-image: url("../../../assets/img/bannerbg.png")   ;
 background-size: cover;
    height: 100vh;
    background-color: black;
    opacity: 0.90; 
    z-index: 1;
} */
.Bgbanner{
    background: linear-gradient(-45deg, #4300AC, #BC0069, #5128C5, #BC0069);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.btn-banner{
    display: flex;
padding: 20px 20px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 6px;
background: rgba(0, 0, 0, 0.20);
backdrop-filter: blur(25px);
color: #FFF;
text-align: center;
font-size: 18px;

font-weight: 500;
line-height: 125%; /* 22.5px */
letter-spacing: 3.6px;
text-transform: uppercase;
transition: all 0.3s ease;
}

@media (min-width: 768px) {
    .btn-banner{
        display: flex;
    padding: 20px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(25px);
    color: #FFF;
    text-align: center;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 22.5px */
    letter-spacing: 3.6px;
    text-transform: uppercase;
    }
}

@media (min-width: 992px) {
    .btn-banner{
        display: flex;
    padding: 20px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(25px);
    color: #FFF;
    text-align: center;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 22.5px */
    letter-spacing: 3.6px;
    text-transform: uppercase;
    }
}
