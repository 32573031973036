.Bgservices{
    background: linear-gradient(-45deg, #4300AC, #BC0069, #5128C5, #BC0069);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;

}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.btnservice{

    display: inline-flex;
padding: 15.833px 39.583px;
justify-content: center;
align-items: center;
gap: 9.896px;
border-radius: 5.938px;
border: 1.979px solid #C52891;
color: #FFF;

font-size: 17.813px;

font-weight: 500;
line-height: normal;
	transition: all 0.3s ease;
}

.btnservice:hover {
    background-color: #C52891; 
    color: #FFF; 
  }
