
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}
* {
  font-family: 'Poppins', sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;


@media (min-width: 768px) {
  .container {
    width: 1200px;
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
  
  }
}

@media (min-width: 992px) {
  .container {
    width: 1200px;
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
  
  }
}
.container{
  padding: 20px;
}
.btn{
  display: inline-flex;
  padding: 15.833px 39.583px;
  justify-content: center;
  align-items: center;
  gap: 9.896px;
  border-radius: 5.938px;
  border: 1.979px solid #C52891;
  color: #FFF;
  font-size: 17.813px;
  font-weight: 500;
  line-height: normal;
}
.btn:hover {
  background-color: #C52891; /* Change the background color on hover */
  color: #FFF; /* Change the text color on hover if needed */
}