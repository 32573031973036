.bg-brands{
    
        position: 'relative';
        flex-direction: column;
        align-items: center;
     justify-content: center;
       background-size: cover;
      
     background-repeat: no-repeat;

}
@media (min-width: 992px) {
    .bg-brands{
    
        position: 'relative';
        flex-direction: column;
        align-items: center;
     justify-content: center;
       background-size: cover;
       height: 100vh;
     background-repeat: no-repeat;

}
}
  
@media (min-width: 768px) {
    .bg-brands{
    
        position: 'relative';
        flex-direction: column;
        align-items: center;
     justify-content: center;
       background-size: cover;
       
     background-repeat: no-repeat;

}

}