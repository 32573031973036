/* 
.slider {
    display: flex;
    width: 60%;
    margin: 0 auto;
    overflow: hidden;
    padding: 2.5rem 0 20px; 
  }
  
  .item {
    animation: animate 20s linear infinite;
    margin: 50px;
  }
  
  .item img {
   padding: 40px;
  }
  
  .slider:hover .item {
    animation-play-state: paused;
  }
  
  @keyframes animate {
    0% {
      transform: translate3d(0, 0, 0);
    }
  
    100% {
      transform: translate3d(-1800px, 0, 0);
    }
  }
  #slider-container {
    position: relative;
    overflow: hidden;
  }
  
  #slider_scroller {
  
    top: 0;
    left: 0;
    width: calc(260px * 6);
    transition: all 1s ease;
  }
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .left {
    left: 10px;
  }
  
  .right {
    right: 10px;
  } */

 
  .Delers .slick-prev,
  .Delers .slick-next {
    font-size: 0;
    background-color: transparent;
    border: none;
    top: 100%;
    z-index: 1;
  }
  
  .Delers .slick-prev:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/img/arrow-left.svg");
    background-size: cover;
  }
  
  .Delers .slick-next:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/img/arrow-right.svg");
    background-size: cover;
  }
  
  .Delers .slick-prev,
  .Delers .slick-next {
    top: 50%;
    
    
  }
  
  @media (min-width: 768px) {
    .Delers .slick-prev,
    .Delers .slick-next {
      top: 50%;
    }
  }
  
  @media (min-width: 992px) {
    .Delers .slick-prev,
    .Delers .slick-next {
      top: 50%;
    }
  }/* Common styles for all media queries */
.Delers .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.Delers .slick-dots li {
  margin: 0 5px;
}

.Delers .slick-dots li button::before {
  /* width: 30px; */
  /* border: solid 1px black; */
  border-radius: 50%;
  cursor: pointer;
  font-size: large;
}

.Delers .slick-dots li.slick-active button::before {
/* width: 30px; */
font-size: large;
}

/* Media query for large screens (992px and above) */
@media (min-width: 992px) {
  .Delers .slick-dots li {
      margin: 0 10px; /* Increase the margin for larger dots */
  }
}

/* Media query for medium screens (768px and above) */
@media (min-width: 768px) {
  .Delers .slick-dots li {
      margin: 0 8px; /* Adjust the margin for medium dots */
  }
}
