.BgTestimonail{
    background-image: url("../../../assets/img/Bgtestimonial.webp")   ;
    
    background-size: cover;
}
/* }
.shadow-effect {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
border:1px solid #ECECEC;
  box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
}
#customers-testimonials .shadow-effect p {
  font-family: inherit;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 300;
}
.testimonial-name {
  margin: -17px auto 0;
  display: table;
  width: auto;
  background: #3190E7;
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0 9px 18px rgba(0,0,0,0.12), 0 5px 7px rgba(0,0,0,0.05);
}
#customers-testimonials .item {
  text-align: center;
  padding: 50px;
  margin-bottom:80px;
  opacity: .2;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#customers-testimonials .owl-item.active.center .item {
  opacity: 1;
  -webkit-transform: scale3d(1.0, 1.0, 1);
  transform: scale3d(1.0, 1.0, 1);
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  max-width: 90px;
  margin: 0 auto 17px;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
  background: #3190E7;
  transform: translate3d(0px, -50%, 0px) scale(0.7);
}
#customers-testimonials.owl-carousel .owl-dots{
display: inline-block;
width: 100%;
text-align: center;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot{
display: inline-block;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot span {
  background: #3190E7;
  display: inline-block;
  height: 20px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.3);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
  width: 20px;
} */

/* .testimonial-slider {
   width: 100%;
    margin: auto;

  }
  
  @media (min-width: 768px) {

    .testimonial-slider {
      width: 70%;
      margin: auto;
  
    }
  }

  @media (min-width: 992px) {

    .testimonial-slider {
      width: 100%;
      margin: auto;
     
  
    }
  } */

  .testimonial-card {
    padding: 20px;
    margin: 0 10px; 
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  
  }
  @media (min-width: 768px) {
    .testimonial-card {
      padding: 20px;
      margin: 0 10px; 
      background-color: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
    }
  }
  @media (min-width: 992px) {
    .testimonial-card {
      padding: 20px;
      margin: 0 10px; 
      background-color: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
    }
  }
  .testimonial-slider  .slick-prev,
  .testimonial-slider .slick-next {
    font-size: 0;
    background-color: transparent;
    border: none;
    top: 100%;
    z-index: 1;
  }
  
  .testimonial-slider  .slick-prev:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/img/client_slider_iconleft.png");
    background-size: cover;
  }
  
  .testimonial-slider .slick-next:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/img/client_slider_iconright.png");
    background-size: cover;

   
  }
  
  .testimonial-slider  .slick-prev,
  .testimonial-slider  .slick-next {
    top: 50%;
  }
  
  @media (min-width: 768px) {
    .testimonial-slider  .slick-prev,
    .testimonial-slider  .slick-next {
      top: 50%;
    }
  }
  
  @media (min-width: 992px) {
    .testimonial-slider .slick-prev,
    .testimonial-slider  .slick-next {
      top: 50%;
    }
  }
  .testimonial-slider .slick-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
    @media (min-width: 992px) {
      .testimonial-slider .slick-dots {
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;
            margin-top: 30px;
         
            padding: 0;
        }
    }

    @media (min-width: 768px) {
      .testimonial-slider .slick-dots {
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;
            margin: 0;
           
            padding: 0;
    }
  }

  .testimonial-slider .slick-dots li {
    margin: 0 5px;
  }

  .testimonial-slider .slick-dots li.slick-active button {
    background-color: white;
    width: 10px;
    height: 10px;
  }

  .testimonial-slider .slick-dots li button {
    background-color: #637780;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 10px;
    height: 10px;
  }  