.bg-contact{
    background-image: url("../../assets/img/ourcareearbg.webp")   ;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}
@media (min-width: 992px) {
    .bg-contact{
        background-image: url("../../assets/img/ourcareearbg.webp")   ;
        background-size: cover;
           background-color: black;
           opacity: 0.90; 
           z-index: 1;  
    }
}

@media (min-width: 768px) {
    .bg-contact{
        background-image: url("../../assets/img/ourcareearbg.webp")   ;
        background-size: cover;
         
           background-color: black;
           opacity: 0.90; 
           z-index: 1;  
    }
}
.bg-contact_us{
    background-image: url("../../assets/img/Contact Us.webp")   ;
    background-size: cover;
    

}
@media (min-width: 992px) {
    .bg-contact_us{
        background-image: url("../../assets/img/Contact Us.webp")   ;
        background-size: cover;
     
    
    }
}

@media (min-width: 768px) {
    .bg-contact_us{
        background-image: url("../../assets/img/Contact Us.webp")   ;
        background-size: cover;
      
    
    }
}