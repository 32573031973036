.Bgfooter{
    background-image: url("../../../assets/img/bgfooter.webp");
    background-size: cover;
 }
 .btnfooter{

    display: inline-flex;
padding: 15.833px 39.583px;
justify-content: center;
align-items: center;
gap: 9.896px;
border-radius: 5.938px;
border: 1.979px solid #C52891;
color: #FFF;

font-size: 17.813px;

font-weight: 500;
line-height: normal;
text-transform: capitalize;
transition: all 0.3s ease;
}
.btnfooter:hover {
  background-color: #C52891; /* Change the background color on hover */
  color: #FFF; /* Change the text color on hover if needed */
}

.myBtn1{
  border-radius: 50%;
  transition: all 0.3s ease;
}

.myBtn1:hover{
    background-color: #C52891;
    border-radius: 50%;
}
 