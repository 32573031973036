.Bg_About{
    background-image: url("../../assets/img/Bg_abbout.webp");
    background-size: cover;
    height: 100vh;
}
.Bg_Aboutsection{

background-size: cover;
background-image: url("../../assets/img/aboutbackground.webp");
/* background-size: cover;

height: 1000px;
background-color: black;
opacity: 0.90; 
z-index: 1; */

}
.visitbg{
    display: inline-flex;
padding: 43px 33px 43px 34px;
justify-content: center;
align-items: center;
background: rgba(50, 50, 50, 0.50);
backdrop-filter: blur(12px);
}
.btnfooter{

    display: inline-flex;
padding: 15.833px 39.583px;
justify-content: center;
align-items: center;
gap: 9.896px;
border-radius: 5.938px;
border: 1.979px solid #C52891;
color: #FFF;

font-size: 17.813px;

font-weight: 500;
line-height: normal;
text-transform: capitalize;
}
.btnfooter:hover {
  background-color: #C52891; /* Change the background color on hover */
  color: #FFF; /* Change the text color on hover if needed */
}
.myBtn1:hover{
    background-color: #C52891;
    border-radius: 50%;
}
 